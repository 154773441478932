const $ = require('jquery');

$( document ).ready(function() {
    const hamburger = $('#hamburger');
    hamburger.click(function() {
        hamburger.toggleClass('active');
        $('.nav').toggleClass('toggle');
        $('.container').slideToggle();
        $('header p').slideToggle();
        $('footer').slideToggle();
        return false;
    });
});